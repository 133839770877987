<template>
    <div class="column is-6-tablet is-3-desktop is-flex is-flex-direction-column tile is-parent is-flex is-flex-direction-column is-align-items-stretch">
        <div :class="classes" @click.capture="selecionar" class="tile is-child" style="padding: 5px;">
            <div class="column is-7">
                <h3>
                    <a v-if="podeAbrirResultado"
                       :href="'/guia/resultado?guiaId=' + guia.id + '&item=' + guiaexame.item + '&digitacao=' + true">

                        <b-tooltip :label="$t(guiaexame.item + ' - ' +  guiaexame.exameNome)" placement="bottom" position="is-bottom" type="is-dark">
                            <strong style="font-size: 14px;"> {{ guiaexame.exameApelido }} </strong>
                        </b-tooltip>
                    </a>
                    <span v-else>
                        <b-tooltip :label="$t(guiaexame.item + ' - ' +  guiaexame.exameNome)" placement="bottom" position="is-bottom" type="is-dark">
                            <strong style="font-size: 14px;"> {{ guiaexame.exameApelido }} </strong>
                        </b-tooltip>
                    </span>
                    <b-tooltip :label="$t('Exame com incidência')" placement="bottom" position="is-bottom" type="is-dark">
                        <b-icon v-if="guiaexame.incidencia && guia.horasDeJejum < guiaexame.minimoJejum"
                                icon="alert-rhombus"
                                size="is-medium"
                                class="is-pulled-right"
                                type="is-warning"></b-icon>
                    </b-tooltip>
                    <b-tooltip v-if="guiaexame.exameNome.length > 29" :label="guiaexame.exameNome" placement="bottom" position="is-bottom" type="is-dark">
                        <div class="has-text-link" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 10px; margin-top: 4px; width: 150px;">
                            {{ guiaexame.exameNome }}
                        </div>
                    </b-tooltip>
                    <div v-else class="has-text-link" style="font-size: 9px; margin-top: 4px; width: 150px;">
                        {{ guiaexame.exameNome }}
                    </div>
                </h3>
            </div>
            
            <div class="column is-1">
                <b-tooltip :label="$t('Exame validado')" placement="top" position="is-top" type="is-dark">
                    <span v-if="validado" class="has-text-success is-pulled-right">&radic;</span>
                </b-tooltip>
            </div>

            <div class="column is-3 has-text-centered">
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('DD/MM/YY') }}</p>
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('HH:mm') }}</p>
            </div>

            <div class="column is-12" v-if="guiaexame.item == 0">
                <div class="field control">
                    <input :placeholder="$t('GUIAEXAME.OUTRASINFORMACOES')" class="input is-small" type="text" maxlength="1000" v-model="guiaexame.outrasInformacoes" expanded>
                </div>
            </div>

            <div v-if="guiaexame.item == 0" style="display:flex">

                <b-div class="column is-2">
                    <b-tooltip :label="$t('GUIAEXAME.EXCLUIREXAME')" placement="bottom" position="is-bottom" type="is-dark">
                        <button class="delete is-small is-pulled-right has-background-danger" @click.stop="$emit('excluirExame', {index, guiaexame})"></button>
                    </b-tooltip>
                </b-div>

                <b-div class="column is-2">
                    <b-tooltip :label="$t('GUIAEXAME.LIGADESLIGAURGENCIA')" placement="bottom" position="is-bottom" type="is-dark">
                        <b-button :class="{ 'is-warning': guiaexame.urgente }"
                                icon-left="alert-circle"
                                size="is-small"
                                :disabled="!isInRole('guia-exame-urgente') && !usuario.convenioPodeIncluirGuia"
                                @click="setUrgente"></b-button>
                    </b-tooltip>
                </b-div>

                <div style="padding: 0.2rem"></div>

                <b-div class="column is-2">
                    <b-tooltip :label="$t('GUIAEXAME.LIGADESLIGAMATERIAL')" placement="bottom" position="is-bottom" type="is-dark">
                        <b-button :class="{ 'is-link': guiaexame.materialEntregue }"
                                v-if="!guiaexame.exameDeFaturamento  && !portal && !guiaexame.exameSetorExamesDeImagem && !guiaexame.exameDeOutrasDespesas && !guiaexame.exameDePerfil"
                                icon-left="water"
                                size="is-small"
                                @click="setMaterialEntregue"></b-button>
                    </b-tooltip>
                </b-div>
                <div style="padding: 0.1rem"></div>

                <b-div class="column is-2">
                    <b-tooltip :label="$t('GUIAEXAME.AUTORIZACAOCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                        <b-button v-if="guiaexame.requerAutorizacao"
                                icon-left="asterisk"
                                size="is-small">
                        </b-button>
                    </b-tooltip>
                </b-div>

                <b-div v-if="(guiaexame.item == 0 || guiaexame.grupoCodigo > 0) && !guiaexame.faturado" style="display: flex; justify-content: center; align-items: center;">
                    <div class="field">
                        <div class="control" v-if="guiaexame.grupoCodigo == 0">
                            <input @change="observacaoMudou" class="input is-small" type="text" maxlength="5" v-model="guiaexame.observacao">
                        </div>
                        <div class="control" v-else>
                            <input @change="observacaoMudou" class="input is-small" type="number" step="0.01" v-model="guiaexame.observacao">
                        </div>
                    </div>
                </b-div>
            </div>

            <div style="display: flex; padding: 0 !important;" class="column is-12" v-if="guiaexame.item > 0">
            <div class="column is-10" style="display: flex;" >
            <div v-if="guiaexame.item > 0 && ((!guiaexame.exameDeFaturamento && !guiaexame.exameDeOutrasDespesas && !guiaexame.exameDePerfil ) || guiaexame.cancelado)" style="display:flex">

            <div style="display: flex; align-items: center; justify-content: center;" class="size-1">
                <b-tooltip position="is-bottom" multilined type="is-dark">
                    <b-tag class="has-text-weight-bold has-text-centered is-size-6" :style="guiaexame.statusHtmlStyle">
                        <span class="sigla">{{ guiaexame.statusSigla }}</span>
                    </b-tag>
                    <template v-slot:content>
                        <b>Status: {{ guiaexame.statusSiglaCompleta }}</b>
                        <br>
                        <p>Histórico: {{ guiaexame.statusTitle }}</p>
                    </template>
                </b-tooltip>
            </div>

            <div v-if="guiaexame.item > 0 && guiaexame.exameDeFaturamento">
                <b-tooltip :label="$t('Exame de Faturamento')" placement="bottom" position="is-bottom" type="is-dark">
                    <div v-if="guiaexame.item > 0 && guiaexame.exameDeFaturamento" class="column">
                        <span class="tag has-text-weight-bold has-text-centered is-size-6">
                            F
                        </span>
                    </div>
                </b-tooltip>
            </div>

            <div  v-if="guiaexame.item > 0 && guiaexame.exameDeOutrasDespesas">
                <b-tooltip :label="$t('Exame de Outras Despesas')" placement="bottom" position="is-bottom" type="is-dark">
                    <div v-if="guiaexame.item > 0 && guiaexame.exameDeOutrasDespesas" class="column">
                        <span class="tag has-text-weight-bold has-text-centered is-size-6">
                            D
                        </span>
                    </div>
                </b-tooltip>
            </div>

            <div v-if="guiaexame.item > 0 && guiaexame.exameDePerfil && !guiaexame.exameDeFaturamento" >
                <b-tooltip :label="$t('Exame de Perfil')" placement="bottom" position="is-bottom" type="is-dark">
                    <div v-if="guiaexame.item > 0 && guiaexame.exameDePerfil && !guiaexame.exameDeFaturamento" class="column">
                        <span class="tag has-text-weight-bold has-text-centered is-size-6">
                            P
                        </span>
                    </div>
                </b-tooltip>
            </div>

            </div>
            <div v-if="guiaexame.item > 0" style="display:flex" > 
                <div style="display: flex; align-items: start;">
                <b-tooltip :label="$t('GUIAEXAME.LIGADESLIGAURGENCIA')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button :class="{ 'is-warning': guiaexame.urgente }"
                              icon-left="alert-circle"
                              size="is-small"
                              :disabled="!isInRole('guia-exame-urgente') && !usuario.convenioPodeIncluirGuia"
                              @click="setUrgente"></b-button>
                </b-tooltip>
                <b-tooltip :label="$t('GUIAEXAME.AUTORIZACAOCONVENIO')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="guiaexame.requerAutorizacao"
                              icon-left="asterisk"
                              size="is-small">
                    </b-button>
                </b-tooltip>
                <template v-if="guiaexame.outrasInformacoes">
                    <b-tooltip placement="bottom" position="is-bottom" type="is-dark">
                        <b-icon icon="note-text-outline"
                                custom-size="mdi-24px">
                        </b-icon>
                        <template v-slot:content>
                            <template v-for="outraInformacao, idx in outrasInformacoesList()">
                                <p :key="idx">{{ outraInformacao }}</p>
                            </template>
                        </template>
                    </b-tooltip>
                </template>
            </div>
            </div>
            <div style="display:flex" >
                <div>
                <div v-if="guiaexame.faturado">
                    <b-tooltip :label="$t('Exame Faturado')" placement="bottom" position="is-top" type="is-dark">
                        <b-icon icon="cash-lock" size="is-small" style="color: #85bb65"></b-icon>
                    </b-tooltip>
                </div>
                
                <router-link v-if="guiaexame.copiaDe"
                                 class="is-pulled-right"
                                 :to="{ name: 'guia', params: { id: guiaexame.copiaDe.guiaId }}">
                        <b-tooltip :label="$t('GUIAEXAME.RESULTADOVINCULADO') + guiaexame.copiaDe.item + $t('GUIAEXAME.GUIAVINCULADO') + guiaexame.copiaDe.guiaId" placement="bottom" position="is-bottom" type="is-dark">
                            <b-icon icon="link"></b-icon>
                        </b-tooltip>
                </router-link>
                </div>
            </div>
            </div>
            <div style="display: flex; justify-content: flex-end;" class="column is-2">
                <div >
                    <b-tag v-if="guiaexame.observacao" >{{ guiaexame.observacao }}</b-tag>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<style scoped>
    .size-1 {
        min-width: 50px;
    }

    .sigla {
        display: inline-block;
        min-width: 1.5em;
        text-align: center; /* Centralize o texto */
    }
</style>

<script>
    import { mapGetters } from 'vuex'
    import { mapState } from 'vuex';

    export default {
        props: {
            guiaexame: Object,
            guia: Object,
            index: Number,
            selecionado: Boolean,
            portal: Boolean,
            validado: Boolean,
        },
        data() {
            return {
                classes: this.defineClasses()
            };
        },
        computed: {
            ...mapState([
                'usuario'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            podeAbrirResultado() {
                return !this.portal &&
                    this.guiaexame.materialEntregue &&
                    !this.guiaexame.cancelado &&
                    !this.guiaexame.bloqueado &&
                    this.guiaexame.item != 0 &&
                    this.isInRole('resultado-editar')
            },
        },
        watch: {
            selecionado: function () {
                this.classes = this.defineClasses()
            }
        },
        methods: {
            outrasInformacoesList() {
                if (this.guiaexame.outrasInformacoes.startsWith('\n')) {
                    this.guiaexame.outrasInformacoes = this.guiaexame.outrasInformacoes.slice(1);
                }
                
                return this.guiaexame.outrasInformacoes.split('\n');
            },
            observacaoMudou(e) {
                this.$emit('observacaoMudou', e.target.value)
            },
            defineClasses() {
                return "columns is-multiline is-mobile guiaExame" +
                    (this.$props.selecionado ? " guiaExameSelecionado" : "")
            },
            setUrgente() {
                this.guiaexame.urgente = !this.guiaexame.urgente
                localStorage.caraterUrgencia = this.guiaexame.urgente
                this.$emit('loadPrecosExames')
            },
            setMaterialEntregue() {
                this.guiaexame.materialEntregue = !this.guiaexame.materialEntregue
                localStorage.materialEntregue = this.guiaexame.materialEntregue
            },
            selecionar() {
                if (this.guiaexame.item != 0) {
                    this.$emit('selecionarExame', this.guiaexame)
                }
            }
        }
    }
</script>

